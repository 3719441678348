if ($(".js-accordion").length) {
    var $accordions = $(".js-accordion");

    $accordions.each(function (i, accordion) {
        var $accordionItems = $(accordion).children(".js-accordion-item");

        $accordionItems.each(function (i, item) {
            var content = $(item).find(".c-accordion__content")[0];
            $(content).slideUp();

            var btn = $(item).find(".c-accordion__btn")[0];
            $(btn).on("click", function () {
                $(item).toggleClass("c-accordion__item--open");
                $(content).slideToggle();
            })

            var closeBtn = $(item).find(".c-accordion__close")[0];
            $(closeBtn).on("click", function () {
                $(item).removeClass("c-accordion__item--open");
                $(content).slideUp();
            })
        });
    })
}